export const ROUTER = {
  //DIGITAL ASSESSMENT
  login: `/login`,
  adminList: "/admin-list",
  examList: "/exam-list",
  evaluator: "/evaluator",
  evaluatorPapers: "/evaluator-papers",
  createExam: "/create-exam",
  //evaluatorLogin: "/login",
  evaluatorDashboard: "/user-dashboard",
  evaluatorAnnotation: "/user-annotation",
  Joblist: "/job-list",
  scan: "/new-scanner",
  setPassword: "/admin/reset-password",
};

export const LABEL = {
  noData: "No data available",
  setPassword: "Please click here to set your password",
  verifyEmail: "Your email has been verified",
  forgotPassword: "Forgot Password",
  forgotHeader:
    "Please enter your email address to receive a verification code!",
  welcome: "Welcome back!",
  loginCredentials: "Please enter you Email/Phone No & password for login.",
  rememberMe: "Remember Me",
  forgorPw: " Forgot Password?",
  resetPw: "Reset Password",
  setupPassword: "Setup Password",
  newPw: "Please enter your new password!",
  back2Login: "Back to Login",
  setPw: "Please click here to set your password",
  emailVerified: " Your email has been verified",
  loading: "Loading...",
  Loading: "Loading...",
};

export const PAGINATION = {
  currentPage: 1,
  itemsPerPage: 10,
  totalPages: 0,
};

export const TOAST = {
  SUCCESS: "success",
  WARNING: "warning",
  DANGER: "danger",
  INFO: "info",
  ERROR: "error",
};
export const MESSAGE = {
  verifySuccess: "Email verified Successfully",
  errorUploadScreenshot: "Upload screenshot  failed",
  forgotPwLink: "Link Sent On Email Address",
  verifyEmail: "Your email has been verified",
  setPassword: "Please click here to set your password",
  notVerfied: "Email has not been verified. Please contact admin.",
  Loading: "Loading...",
  apiFail: "API is not responding",
  tokenExpired: "Token is expired",
  successUser: "User successfully registered",
  successSuperAdmin: "SuperAdmin Successfully Logged In",
  successLogin: "Successfully Logged In",
  resendOtpError: "Too many OTP requests, please try again later",
  resendOtpFailed: "Failed to resend OTP.",
  mobileAccess: " Access To Mobile App",
  emailLink:
    "User will receive a link on the email to set password and activate the account.",
  role: "Role",
  forgotPassword: "Forgot Password",
  forgotHeader:
    "Please enter your email address to receive a verification code!",
  //Forgot password
  forgotTokenExpired:
    "Forgot password link has expired, please contact admin for help",

  //DIGITAL ASSESSMENT
  success: "Successfully",
  failure: "Failed",
  deleteAdmin: "Admin deleted successfully",
  commonError: "Something went wrong",
  passwordUpdated: "Password updated Successfully",
  deleteExam: "Exam deleted successfully",
  deleteEvaluator: "Evaluator deleted successfully",
  faceVerificationFailed: "Face verification failed",
  deleteJob: "Job deleted successfully",
  addAnnotation: "Please add at least one annotation",
  markRequired: "Mark is required",
  invalidRolePermission: "Invalid role permission",
  welcome: "Welcome back!",
  loginCredentials: "Please enter you user id & password for login.",
  rememberMe: "Remember Me",
  forgorPw: " Forgot Password?",
  resetPw: "Reset Password",
  newPw: "Please enter your new password!",
  back2Login: "Back to Login",
  setPw: "Please click here to set your password",
  emailVerified: " Your email has been verified",
  loading: "Loading...",
  deletePaper: "Paper deleted successfully",
  evaluated: "Evaluation for this question has already been done.",
  noCandidateData: "No Candidate Data found for this question",
  completedEvaluation: "Evaluation has been completed for this question.",
  ValidatePaperCode: "Paper Code name is already exist",
};

//DIGITAL ASSESSMENT
export const employeeEmailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
export const mobileNumberRegex = /^\d{10}$/;
//export const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/
// export const passwordRegex =/^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[!@#\$%\^\&*\(\)_\+\-=\[\]\{\};:'",.<>\/?\\|`~])\S{7,}$/,
export const passwordRegex =
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*\W)(?=.{8,})/;

export const emailOrPwdRegex = /^(\S+@\S+\.\S+|\d{10})$/;

export const TOOLTIP_MSG = {
  EDIT: "Edit",
  DELETE: "Delete",
  VIEW: "View",
  APPLICATION: "Application",
  VIEW_CHAT: "View Chat",
  VIEW_HISTORY: "View History",
  PSWD_CHANGE: "Change Password",
  EVALUATION: "Start Evaluation",
};

export const ANNOTATION_MASTERS_ID = {
  tick: "00000000-0000-0000-0000-000000000001",
  cross: "00000000-0000-0000-0000-000000000002",
  question: "00000000-0000-0000-0000-000000000003",
  slash: "00000000-0000-0000-0000-000000000004",
  ellipse: "00000000-0000-0000-0000-000000000005",
  minus: "00000000-0000-0000-0000-000000000006",
  remark: "00000000-0000-0000-0000-000000000007",
  marks: "00000000-0000-0000-0000-000000000008",
};

export const accessOptions = [
  "All",
  "Job Card",
  "Scanner",
  "Evaluator",
  "Report",
];

export const ACCEPTED_IMAGE_FORMATS = "image/jpeg,image/png,image/jpg";

export enum TAB_PERMISSION {
  JOB = "jobCard",
  SCANNER = "scanner",
  EVALUATOR = "evaluator",
  REPORT = "report",
}

export enum ACTION_TYPE {
  ADD = "add",
  EDIT = "edit",
  DELETE = "delete",
  CHANGE_PASSWORD = "changePassword",
}

export enum ROUTE_NAME {
  ADMIN_LIST = "admin-list",
  EXAM_LIST = "exam-list",
  EXAM = "exam",
}
