//DIGITAL ASSESSMENT
export const REQUIRED = {
  EMAIL_OR_PHONE: " Email/Phone is required",
  COMPANYLOGO: "Company Logo Required",
  VALID_EMAIL: "Invalid email address",
  EMAIL_REQUIRED: "Email is required",
  CONFIRM_PASSWORD: "Confirm Password Required",
  JOB_EXAM_ID: "Please enter a exam id",
  SUB_CODE: "Please enter a subject code",
  JOBCARD_NO: "Please enter a jobcard number",
  CENTER_CODE: "Please enter a center code",
  TOTAL_ANSBOOK: "Please enter a total answer book",
  NAME: "Name is required",
  EMAIL: "Email is required",
  PASSWORD: "Password is required",
  MOBILE_NO: "Mobile number is required",
  USER_ID: "User id is required",
  START_DATE: "Start date is required",
  END_DATE: "End date is required",
  USER_NAME: "User id is required",
  EXAM_ID: "Exam id is required",
  EXAM_NAME: "Exam name is required",
  EXAM_TYPE: "Exam type is required",
  EXAM_DATE: "Exam date is required",
  PAPER_DATE: "Paper date is required",
  PAPER_NAME: "Paper name is required",
  PAPER_CODE: "Paper code is required",
  TOTAL_MARKS: "Total marks is required",
  TOTAL_NUMBER_OF_PAGES: "Total number of pages is required",
  TOTAL_CANDIDATES: "Page per ansbook is required",
  START_UID: "Start uid is required",
  START_PAGE: "Start page is required",
  GROUP: "Group is required",
  PHOTO: "Photo is required",
  ANNOTATION_REMARK: "Annotation Remark is required",
  JOBCARD_DETAIL: "Jobcard detail is required",
  UID_BACK_CODE: "UID back code is required",
  UID_FRONT_CODE: "UID front code is required",
  CANDIDATE_BC: "Barcode No. is required",
  SCAN_EXAM_ID: "Please select exam id",
  SUB_ID: "Please select subject code",
  CANDIDATE_CODE: "Enter valid candidate code",
  CANDIDATE_UID: "Enter valid candidate UID",
  EXAM_CODE: "Please Select Exam Code",
  PAPER_CODE_SELECT: "Please select paper code",
  QUESTION_SELECT: "Please select question id",
  PERMISSION: "Please select a permission",
};

export const VALIDATE = {
  EMAIL: "Please enter a valid email",
  MOBILE: "Mobile number must be a 10 digit",
  PASSWORD:
    "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character",
  CONTACT_NUMBER: "Contact number must be a number",
  PASSWORD_DOES_NOT_MATCH: "Password does not match",
  YOUR_NEW_PASSWORDS_DO_NOT_MATCH: "Your new passwords do not match",
  EXAM_NAME: "Please enter a exam name",
  EXAM_ID: "Please enter a exam id",
  PAPER_CODE: "Please enter a paper code",
  EXAM_DATE: "Please enter a exam id",
  EXAM_TIME: "Please enter a exam time",
  GROUP: "Please select a group",
  VALID_EMAIL_OR_PHONE: "Enter a valid email or 10-digit phone number",

  //PASSWORD: 'Please enter a password',
};
