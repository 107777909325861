export const API_URL = {
  default: process.env.NEXT_PUBLIC_API_URL,
};
//export const DEBOUNCE_TIME = 1000;
//export const WEB_SOCKET_URL = process.env.NEXT_PUBLIC_WEB_SOCKET_URL;
export const RESPONSE_TYPE = {
  blob: "blob",
};

export const API_NAME = {
  verifyEmail: "user/verifyemail",
  updatePassword: "user/updatepassword",
  verifyForgotPasswordToken: "user/verifyforgotpasswordlink",
  login: "login",
  userRegistration: "user/register",
  getRoles: "role/getall",
  forgotPassword: "user/forgotpassword",
  setPassword: "user/setpassword",
  changePassword: "auth/reset-password",
  scheduleExam: "ExamMaster/create",
  superAdminLogin: "login",
  adminCreate: "user/create",
  adminList: "admin/get-all",
  adminEdit: "user/update",
  adminDelete: "user/delete",
  deleteUser: "user/delete",
  getAdminById: "user/get-by-id",
  examList: "exam/get-all-exam",
  createExam: "exam/create",
  evaluatorCreate: "user/create",
  addUser: "user-permission/create-user-with-permission",
  editUser: "user-permission/update-user-with-permission",
  getUserById: "user/get-user-by-id",
  getUsersPermission: "user-permission/get-all-users-permission",
  getUserPermission: "user-permission/get-users-permission-by-id",
  evaluatorList: "evaluator/get-all-evaluators",
  scannerCreate: "user/create",
  getScannerList: "scanning/get-all-scanners",
  getScannerJobList: "scanning/get-all-jobs-for-scanners",
  jonDropdown: "job-card/get-all-job-cards/dropdown",
  updateScanner: "user/update",
  evaluatorEdit: "user/update",
  scannerUpdate: "user/update",
  scannerById: "scanning/get-scanner-by-id",
  scannerPaperDetails: "paper-details/get-by-id",
  evaluatorDelete: "user/delete",
  getEvaluatorById: "evaluator/get-evaluator-by-id",
  evaluatorAssignedPaper: "evaluator/get-all-questions-for-evaluators",
  getGroupData: "group-master/get-all/dropdown",
  uploadUserImage: "user/uploadImage",
  deleteExam: "exam/delete-exam-by-id",
  deletePaper: "paper-details/delete-paper-detail-by-id",
  deleteBarcodeDetails: "barcode-detail/delete-barcode-details-by-id",
  savePaperDetails: "paper-details/save",
  createQuestionpattern: "question-pattern/save",
  getAllGroupList: "group-master/get-all-group",
  createGroup: "group-master/create",
  deleteGroup: "group-master/delete-group-by-id",
  getAllPaperCode: "paper-details/all/dropdown",
  getAllPaperPattern: "question-pattern/get-all-question-pattern/dropdown",
  getAllSubjectList: "subject-master/all/dropdown",
  userVerify: "auth/verify-user",
  evaluatorExamList: "exam-master/evaluator-exam-list",
  examReportView: "exam-master/report",
  getexamById: "exam/get-by-id",
  getallpaperDetails: "paper-details/get-all-paper-details",
  updateExamMaster: "exam/update",
  getPaperPattern: "question-pattern/get-all-question-pattern",
  saveAnnotation: "evaluation/save-result",
  saveAnnotationScreenshotImages: "evaluation/upload-annotated-images",
  paperRemark: "paper-evaluation/get-all-remarks",
  getPaperEvaluation: "evaluation/get-all-annotations",
  saveScreenShot: "evaluation/save-screenshot",
  jobCard_list: "job-card/get-all-job-cards",
  getAllExamId: "exam/get-all-exam/dropdown",
  createJobmasterCard: "job-card/create",
  GetvalidateJobCode: "job-card/validate-job-code",
  deleteJobmasterCard: "job-card/delete-job-card-by-id",
  getJobcardDetailList: "job-card/get/all-job-cards-by-paper-detail-master-id",
  getJobBarcodeDetailList:
    "barcode-detail/get-all-barcode-details-by-job-card-master-id",
  getAllbarcodeDetails: "UID-detail/get/all-barcode-details-by-UID-detail-id",
  createjobBarcode: "barcode-detail/create",
  getAllScanningJobCard: "scanning/get-all-job-cards",
  uploadScanImages: "scanning/upload-scanned-images",
  getValidDetails: "scanning/is-valid-candidate",
  updateBarcode: "job-card-master/update",
  getAllCroppedImages: "scanning/get-all-scanned-images",
  createcropDetailController: "crop-details/create",
  //reports
  getExamDropdownOptions: "exam-master/get-all-exam/dropdown",
  getSubjectDropdownOptions: "subject-master/all/dropdown",
  getCandidateDropdownOptions: "student/get-all-completed-students/dropdown",
  getReportData: "report/get-excel-report",
  getPdfReport: "report/get-pdf-report",
  getAllpaperPatternQuestions:
    "question-pattern/get-all-question-pattern/dropdown",
  getParticularCroppedImage: "crop-details/get-crop-details",
  getPaperCodeDropDownData: "paper-details/all/dropdown",
  getQuestionDropDownData: "question-pattern/get-all-questions-dropdown",
  getEvaluatorReport: "report/evaluator",
  getResultReport: "report/result",
  getScannerReport: "report/scanner",
  papersDropdown: "paper-details/all/dropdown",
  jobsDropdown: "job-card/get-all-job-cards/dropdown",
  candidateDropdown: "student/get-all-completed-students/dropdown",
  getBreadCrumbs: "breadcrumb/get",
  getEvaluatorPdf: "report/evaluator-pdf",
  getEvaluatorExcel: "report/evaluator-xls",
  getScannerPdf: "report/scanner-pdf",
  getScannerExcel: "report/scanner-xls",
  getResultPdf: "report/result-pdf",
  getResultExcel: "report/result-xls",
  setPapervalidateCode: "paper-details/validate-paper-code",
};
