import * as Yup from "yup";
import {
  emailOrPwdRegex,
  employeeEmailRegex,
  mobileNumberRegex,
  passwordRegex,
} from "./commonConstant";
import { REQUIRED, VALIDATE } from "./validation";

// Maximum file size in bytes (5MB)
const MAX_FILE_SIZE = 5 * 1024 * 1024; // 5 MB

// Allowed file types
const ALLOWED_FILE_TYPES = ["image/jpeg", "image/png", "image/jpg"];

export const resetPasswordFormSchema = Yup.object().shape({
  email: Yup.string().required(REQUIRED.EMAIL).email(VALIDATE.EMAIL),
  password: Yup.string()
    .required(REQUIRED.PASSWORD)
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
      "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"
    ),
});

export const forgotPasswordFormSchema = Yup.object().shape({
  emailId: Yup.string()
    .required(REQUIRED.EMAIL_REQUIRED)
    .email(REQUIRED.VALID_EMAIL)
    .matches(employeeEmailRegex, REQUIRED.VALID_EMAIL),
});
//DIGITAL ASSESAMWNT

export const AddAdminFormSchema = Yup.object().shape({
  name: Yup.string().required(REQUIRED.NAME).trim(),
  email: Yup.string()
    .required(REQUIRED.EMAIL)
    .email(VALIDATE.EMAIL)
    .matches(employeeEmailRegex, VALIDATE.EMAIL)
    .trim(),
  mobileNo: Yup.string()
    .trim()
    .required(REQUIRED.MOBILE_NO)
    .matches(mobileNumberRegex, VALIDATE.MOBILE)
    .trim(),
  // userId: Yup.string().required(REQUIRED.USER_ID).trim(),
  password: Yup.string()
    .required(REQUIRED.PASSWORD)
    .matches(passwordRegex, VALIDATE.PASSWORD)
    .trim(),
  startDate: Yup.date()
    .typeError(REQUIRED.START_DATE)
    .required(REQUIRED.START_DATE),
});

export const EditAdminFormSchema = Yup.object().shape({
  name: Yup.string().required(REQUIRED.NAME).trim(),
  email: Yup.string()
    .required(REQUIRED.EMAIL)
    .email(VALIDATE.EMAIL)
    .matches(employeeEmailRegex, VALIDATE.EMAIL)
    .trim(),
  mobileNo: Yup.string()
    .required(REQUIRED.MOBILE_NO)
    .matches(mobileNumberRegex, VALIDATE.MOBILE)
    .trim(),
  // userId: Yup.string().required(REQUIRED.USER_ID).trim(),
  startDate: Yup.date()
    .typeError(REQUIRED.START_DATE)
    .required(REQUIRED.START_DATE),
});

export const ExamformSchema = Yup.object().shape({
  examCode: Yup.string().required(REQUIRED.EXAM_CODE),
  examName: Yup.string().required(REQUIRED.EXAM_NAME),
  examDate: Yup.string().required(REQUIRED.EXAM_DATE),
  examType: Yup.string().required(REQUIRED.EXAM_TYPE),
});
export const paperQuestionTypeSchema = Yup.object().shape({
  questionType: Yup.string().required("select Question paperpattern"),
});
export const SubjectMasterSchema = Yup.object().shape({
  paperDate: Yup.string().required(REQUIRED.PAPER_CODE),
  paperName: Yup.string().required(REQUIRED.PAPER_NAME),
  paperCode: Yup.string().required(REQUIRED.PAPER_CODE),
  totalMarks: Yup.string().required(REQUIRED.TOTAL_MARKS),
  totalAnswerBook: Yup.string().required(REQUIRED.TOTAL_NUMBER_OF_PAGES),
  numberOfPagesPerAnswerBook: Yup.string().required(REQUIRED.TOTAL_CANDIDATES),
  startUID: Yup.string().required(REQUIRED.START_UID),
  scanStartPageNumber: Yup.string().required(REQUIRED.START_PAGE),
});

export const remarkMasterSchema = Yup.object().shape({
  AnnotationRemarks: Yup.string().required(REQUIRED.ANNOTATION_REMARK),
});

export const QuestionpaperpatternQuestions = Yup.object().shape({
  paperpatternQuestions: Yup.string().required("Question No is required"),
});

export const PaperPatternSchema = Yup.object().shape({
  paperCode: Yup.string().required(REQUIRED.PAPER_CODE),
  // groupName: Yup.string().required('group name is required'),
  questionMarks: Yup.string().required("Question Marks are required"),
  questionNo: Yup.string().required("Question No is required"),
  // questionType: Yup.string().required('select Question paperpattern'),
  // questionNo: Yup.string().required("Question No is required"),
  // subQuestionId: Yup.string().required('Sub Question ID is required'),
  // nestedQuestionId: Yup.string().required('Nested Question ID is required'),
  // questionNo: Yup.number()
  // .typeError("Question No must be a number")
  // .required("Question No are required")
  // .transform((value, originalValue) => (originalValue === "" ? undefined : value)),
  pageFrom: Yup.number()
    .typeError("Page from must be a number")
    .required("Page from is required")
    .transform((value, originalValue) =>
      originalValue === "" ? undefined : value
    ),
  pageTo: Yup.number()
    .typeError("Page to must be a number")
    .required("Page to is required")
    .transform((value, originalValue) =>
      originalValue === "" ? undefined : value
    ),
});

export const signInFormSchema = Yup.object().shape({
  // email: Yup.string().required(REQUIRED.EMAIL).email(VALIDATE.EMAIL),
  //userName: Yup.string().required(REQUIRED.USER_NAME).trim(),
  userName: Yup.string()
    .required(REQUIRED.EMAIL_OR_PHONE)
    .matches(emailOrPwdRegex, VALIDATE.VALID_EMAIL_OR_PHONE),
  password: Yup.string().required(REQUIRED.PASSWORD).trim(),

  // export const employeeEmailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  //export const mobileNumberRegex = /^\d{10}$/;
  //  .matches(
  //      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
  //      'Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character',
  //  ),
});

export const jobcardMasterSchema = Yup.object().shape({
  paperCode: Yup.string().required(REQUIRED.PAPER_CODE),
  // subjectCode: Yup.string().required(REQUIRED.SUB_CODE),
  jobCode: Yup.string().required(REQUIRED.JOBCARD_NO),
  centreCode: Yup.string().required(REQUIRED.CENTER_CODE),
  totalAnswerBookInJob: Yup.string().required(REQUIRED.TOTAL_ANSBOOK),
});

export const jobDetailMasterSchema = Yup.object().shape({
  barcodeNumber: Yup.number()
    .typeError("Page from must be a number")
    .required(REQUIRED.CANDIDATE_BC)
    .transform((value, originalValue) =>
      originalValue === "" ? undefined : value
    ),
  uidNumberFront: Yup.number()
    .typeError("Page to must be a number")
    .required(REQUIRED.UID_FRONT_CODE)
    .transform((value, originalValue) =>
      originalValue === "" ? undefined : value
    ),
  uidNumberBack: Yup.number()
    .typeError("Page to must be a number")
    .required(REQUIRED.UID_BACK_CODE)
    .transform((value, originalValue) =>
      originalValue === "" ? undefined : value
    ),
  // jobCardNumber: Yup.string().required(REQUIRED.JOBCARD_DETAIL),
});
export const resetPasswordSchema = Yup.object().shape({
  password: Yup.string()
    .required(REQUIRED.PASSWORD)
    .matches(passwordRegex, VALIDATE.PASSWORD)
    .trim(),
  confirmPassword: Yup.string()
    .required(REQUIRED.CONFIRM_PASSWORD)
    .oneOf([Yup.ref("password")], VALIDATE.PASSWORD_DOES_NOT_MATCH)
    .trim(),
});

// Function to validate file type
const isValidFileType = (fileName: string) => {
  const extension = fileName?.split(".").pop();
  return ALLOWED_FILE_TYPES.includes(`image/${extension}`);
};

export const AddEvaluatorFormSchema = Yup.object().shape({
  name: Yup.string().required(REQUIRED.NAME).trim(),
  email: Yup.string()
    .required(REQUIRED.EMAIL)
    .email(VALIDATE.EMAIL)
    .matches(employeeEmailRegex, VALIDATE.EMAIL)
    .trim(),
  mobileNo: Yup.string()
    .trim()
    .required(REQUIRED.MOBILE_NO)
    .matches(mobileNumberRegex, VALIDATE.MOBILE)
    .trim(),
  // userId: Yup.string().required(REQUIRED.USER_ID).trim(),
  password: Yup.string()
    .required(REQUIRED.PASSWORD)
    .matches(passwordRegex, VALIDATE.PASSWORD)
    .trim(),
  photo: Yup.mixed()
    .required(REQUIRED.PHOTO) // Field required
    .test(
      "is-valid-type",
      "Only JPG,JPEG and PNG files are allowed.",
      /* eslint-disable @typescript-eslint/no-explicit-any */
      (value: any) => value && isValidFileType(value?.name?.toLowerCase())
    )
    .test(
      "is-valid-size",
      "Max allowed size is 5MB.",
      /* eslint-disable @typescript-eslint/no-explicit-any */
      (value: any) => value && value.size <= MAX_FILE_SIZE
    ),
  paperCode: Yup.string().required(REQUIRED.PAPER_CODE_SELECT),
  questionId: Yup.string().required(REQUIRED.QUESTION_SELECT),
});
export const EditEvaluatorFormSchema = Yup.object().shape({
  name: Yup.string().required(REQUIRED.NAME).trim(),
  email: Yup.string()
    .required(REQUIRED.EMAIL)
    .email(VALIDATE.EMAIL)
    .matches(employeeEmailRegex, VALIDATE.EMAIL)
    .trim(),
  mobileNo: Yup.string()
    .trim()
    .required(REQUIRED.MOBILE_NO)
    .matches(mobileNumberRegex, VALIDATE.MOBILE)
    .trim(),
  // userId: Yup.string().required(REQUIRED.USER_ID).trim(),
  photo: Yup.mixed()
    .required(REQUIRED.PHOTO)
    /* eslint-disable @typescript-eslint/no-explicit-any */
    .test(
      "is-valid-type",
      "Only JPG,JPEG and PNG files are allowed.",
      (value: any) => {
        // Skip validation if value is a URL (fileURL)
        if (typeof value === "string" && value.startsWith("http")) {
          return true;
        }
        // Perform validation for uploaded files
        return value && isValidFileType(value?.name?.toLowerCase());
      }
    )
    /* eslint-disable @typescript-eslint/no-explicit-any */
    .test("is-valid-size", "Max allowed size is 5MB.", (value: any) => {
      // Skip size check if value is a URL (fileURL)
      if (typeof value === "string" && value.startsWith("http")) {
        return true;
      }
      // Perform size check for uploaded files
      return value && value.size <= MAX_FILE_SIZE;
    }),
});

export const scanExamFormSchemaManual = Yup.object().shape({
  code: Yup.number()
    .typeError(REQUIRED.CANDIDATE_CODE)
    .required(REQUIRED.CANDIDATE_CODE),
  uId: Yup.string().required(REQUIRED.CANDIDATE_UID),
});

export const scanExamFormSchemaAuto = Yup.object().shape({
  examId: Yup.string().required(REQUIRED.SCAN_EXAM_ID),
  subjectId: Yup.string().required(REQUIRED.SUB_ID),
  code: Yup.number()
    .typeError(REQUIRED.CANDIDATE_CODE)
    .required(REQUIRED.CANDIDATE_CODE),
  uId: Yup.string().required(REQUIRED.CANDIDATE_UID),
});

export const reportFilterSchema = Yup.object().shape({
  examCode: Yup.string().required("Please Select Exam Code"),
  subjectCode: Yup.string(),
  candidate: Yup.string(),
});

export const scannerSchema = (isUpdate: boolean) =>
  Yup.object().shape({
    name: Yup.string().required("Name is required"),

    emailId: Yup.string()
      .required(REQUIRED.EMAIL)
      .email(VALIDATE.EMAIL)
      .matches(employeeEmailRegex, VALIDATE.EMAIL)
      .trim(),

    mobileNumber: Yup.string()
      .trim()
      .required(REQUIRED.MOBILE_NO)
      .matches(mobileNumberRegex, VALIDATE.MOBILE)
      .trim(),

    password: Yup.string().when([], {
      is: () => !isUpdate, // When it's not an update, make it required
      then: (schema) =>
        schema
          .required(REQUIRED.PASSWORD)
          .matches(
            /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
            "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"
          ),
      otherwise: (schema) => schema.optional(),
    }),

    jobCodeId: Yup.array()
      .of(Yup.string())
      .test("jobValidation", "Please select job code", (value) => {
        return value?.length ? true : false;
      }),

    file: Yup.mixed().when([], {
      is: () => !isUpdate,
      then: (schema) =>
        schema
          .required("Please select a file")
          .test("fileValidation", "Invalid file format", (file: any) => {
            if (typeof file === "string") return true;

            if (!file || file.length === 0) {
              return false;
            }

            const uploadedFile = file;

            const isSizeValid = uploadedFile.size <= MAX_FILE_SIZE;
            const isFormatValid = ALLOWED_FILE_TYPES.includes(
              uploadedFile.type
            );

            return isSizeValid && isFormatValid;
          }),
      otherwise: (schema) => schema.optional(),
    }),
  });

export const AddUserFormSchema = Yup.object().shape({
  name: Yup.string().required(REQUIRED.NAME).trim(),
  emailId: Yup.string()
    .required(REQUIRED.EMAIL)
    .email(VALIDATE.EMAIL)
    .matches(employeeEmailRegex, VALIDATE.EMAIL)
    .trim(),
  mobileNumber: Yup.string()
    .trim()
    .required(REQUIRED.MOBILE_NO)
    .matches(mobileNumberRegex, VALIDATE.MOBILE)
    .trim(),
  // userId: Yup.string().required(REQUIRED.USER_ID).trim(),
  password: Yup.string()
    .required(REQUIRED.PASSWORD)
    .matches(passwordRegex, VALIDATE.PASSWORD)
    .trim(),
  // userAccess: Yup.array()
  //   .typeError(REQUIRED.PERMISSION)
  //   .required(REQUIRED.PERMISSION),
});

export const EditUserFormSchema = Yup.object().shape({
  name: Yup.string().required(REQUIRED.NAME).trim(),
  emailId: Yup.string()
    .required(REQUIRED.EMAIL)
    .email(VALIDATE.EMAIL)
    .matches(employeeEmailRegex, VALIDATE.EMAIL)
    .trim(),
  mobileNumber: Yup.string()
    .trim()
    .required(REQUIRED.MOBILE_NO)
    .matches(mobileNumberRegex, VALIDATE.MOBILE)
    .trim(),
  // userId: Yup.string().required(REQUIRED.USER_ID).trim(),
  // password: Yup.string()
  //   .required(REQUIRED.PASSWORD)
  //   .matches(passwordRegex, VALIDATE.PASSWORD)
  //   .trim(),
  // userAccess: Yup.array()
  //   .typeError(REQUIRED.PERMISSION)
  //   .required(REQUIRED.PERMISSION),
});
